<template>
  <v-menu
    offset-y
    :nudge-top="$vuetify.breakpoint.mdAndUp ? '0' : '4'"
    v-model="menuVisible"
    v-if="$vuetify.breakpoint.smAndUp"
    shrink
    content-class="zg-select-border-radius"
  >
    <template v-slot:activator="{ on }">
      <v-layout
        align-center
        v-on="on"
        :class="buttonMargin"
        shrink
        class="clickable"
      >
        <DefaultLabel class="mr-2">{{ selected.modified }}</DefaultLabel>
        <v-icon small class="black--text">{{
          menuVisible ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-layout>
    </template>
    <v-list dense>
      <v-list-item class="zg-um-list">
        <v-list-item-title><b>Historia zmian</b></v-list-item-title>
        <v-spacer></v-spacer>
      </v-list-item>
      <v-list-item
        v-for="delegate in delegates"
        :key="delegate.id"
        @click.prevent="$emit('update:selected', delegate)"
        class="zg-um-list"
      >
        <v-list-item-title
          :class="
            delegate.history_id == selected.history_id
              ? 'primary--text'
              : 'black--text'
          "
          >{{ delegate.modified }}</v-list-item-title
        >
        <v-spacer></v-spacer>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    selected: {},
    delegates: {},
  },
  computed: {
    buttonMargin() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return "px-1";
      } else if (this.$vuetify.breakpoint.smOnly) {
        return "px-2";
      } else {
        return "px-6";
      }
    },
  },
  data() {
    return {
      menuVisible: false,
    };
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
};
</script>

<style lang="scss">
.clickable {
  cursor: pointer;
}
.zg-um-list .v-list__tile.v-list__tile--link:hover {
  background-color: rgb(245, 250, 254) !important;
}
</style>